.product-image {
  width: 100%;
  height: 300px;

  @media only screen and (max-width: 786px) {
    height: 200px;
  }

  background-position: center -80px;
  background-size: cover;
  background-repeat: no-repeat;
}

.packages-container {
  position: relative;

  &:before {
    content: "";
    height: 99px;
    border-left: 4px dotted #4273a2;
    position: absolute;
    top: -99px;
    z-index: -1;
  }
}
